import React from 'react'
import Helmet from 'react-helmet'
import Layout from '../components/layout'
import PageBanner from '../components/PageBanner'

const FoodmenuFloersheim = (props) => (
    <Layout>
        <Helmet>
            <title>Speisekarte - Dogan Döner Pizza Haus- Essen zum Mitnehmen oder Lieferung  - Online bestellen</title>
            <meta name="description" content="Dogan Döner Pizza Haus, Speisekarte, Online Essen Bestellen"/>
        </Helmet>
        <PageBanner pageTitle="Speisekarte"/>

        <div id="main" className="alt-grey grid-wrapper">
            <section id="one" className="col-9">
                    <div className="s-category-container js-collapsible s-category-has-picture">

                        <div className="s-category-name-container">
                            <div className="s-category-name">Döner Gerichte</div>


                        </div>


                        <div className="s-items" >

                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi790726">
                                <div className="s-item">
                                    <div className="s-item-info w-75 s-item-has-picture">
                                        <div className="s-item-name">01.   Döner Kebab Groß</div>
                                        <div className="s-item-description">mit Dönerfleisch, Salat &amp; Soße</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>4,50</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi791931">
                                <div className="s-item">
                                    <div className="s-item-info w-75 s-item-has-picture">
                                        <div className="s-item-name">02.  Döner Kebab Klein</div>
                                        <div className="s-item-description">mit Dönerfleisch, Salat &amp; soße</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>3,50</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi791933">
                                <div className="s-item">
                                    <div className="s-item-info w-75 s-item-has-picture">
                                        <div className="s-item-name">03.  Döner Kebab Groß mit Weichkäse</div>
                                        <div className="s-item-description">mit Dönerfleisch, Salat, soße &amp; Weichkäse</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>5,50</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi795046">
                                <div className="s-item">
                                    <div className="s-item-info w-75 s-item-has-picture">
                                        <div className="s-item-name">04.   Döner Kebab Klein mit Weichkäse</div>
                                        <div className="s-item-description">mit Dönerfleisch, Salat, soße &amp; Weichkäse</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>4,00</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi795047">
                                <div className="s-item">
                                    <div className="s-item-info w-75 s-item-has-picture">
                                        <div className="s-item-name">05.  Mega Döner</div>
                                        <div className="s-item-description">mit Dönerfleisch, Salat &amp; soße</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>5,50</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi795049">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">06.  Mega Döner mit Weichkäse</div>
                                        <div className="s-item-description">mit Dönerfleisch, Salat, soße &amp; Weichkäse</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>6,50</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi795050">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">07. Dönerbox</div>
                                        <div className="s-item-description">mit Dönerfleisch Salat oder Pommes &amp; soße</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>5,00</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi801865">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">08. Große Vegetarisch Sandwich</div>
                                        <div className="s-item-description">mit Salat, Weichkäse &amp; Soße</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>4,00</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi801870">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">09. Klein  Vegetarisch Sandwich</div>
                                        <div className="s-item-description">mit Salat, Weichkäse &amp; Soße</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>3,00</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi801871">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">10. Yufka Döner</div>
                                        <div className="s-item-description">mit Dönerfleisch, Salat &amp; soße</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>5,00</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi801872">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">11. Yufka Döner</div>
                                        <div className="s-item-description">mit Dönerfleisch, Salat,  soße &amp; Weichkäse</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>6,00</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi801873">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">12. Yufka Vegetarisch</div>
                                        <div className="s-item-description">mit  Salat,  soße &amp; Weichkäse</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>4,50</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi801876">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">13. Döner Teller</div>
                                        <div className="s-item-description">mit Pommes &amp; Soße</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>7,50</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi801877">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">14. Döner Teller</div>
                                        <div className="s-item-description">mit Reis &amp; Soße</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>7,50</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi801878">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">15.  Döner Teller</div>
                                        <div className="s-item-description">mit Salat &amp; Soße</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>7,50</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi801879">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">16.  Döner Teller</div>
                                        <div className="s-item-description">mit Salat, Pommes oder Reis &amp; Soße</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>8,50</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi801880">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">17.  Döner Teller</div>
                                        <div className="s-item-description">mit Salat, Pommes oder Reis,  Soße &amp; Weichkäse</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>9,00</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi801881">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">18. Falafel Teller</div>
                                        <div className="s-item-description">mit kichererbsenbällchen &amp; soße</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>7,00</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi801882">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">19. Falafel in Fladenbrot</div>
                                        <div className="s-item-description">mit kichererbsenbällchen &amp; soße</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>4,50</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi801883">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">20. Falafel im Yufka</div>
                                        <div className="s-item-description">mit kichererbsenbällchen &amp; soße</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>5,00</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi801875">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">21. Yufka Spezial</div>
                                        <div className="s-item-description">Teigrolle gefüllt  mit Dönerfleisch, Salat, Tomatensoße, Käse überbacken  und Soße</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>6,50</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="s-category-container js-collapsible s-category-has-picture">

                        <div className="s-category-name-container">
                            <div className="s-category-name">Snacks</div>


                        </div>


                        <div className="s-items" >

                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi801888">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">24.	Kiddy box</div>
                                        <div className="s-item-description">Döner oder Nuggets,  Pommes, Capri-Sonne und weitere Überraschungen</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>5,50</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi801887">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">25.	Chicken Nuggets</div>
                                        <div className="s-item-description">6 Stück</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>3,00</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi801886">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">26.	Chicken Nuggets</div>
                                        <div className="s-item-description">6 Stück mit Pommes</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>5,50</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi801885">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">27.	Pommes Frites</div>
                                        <div className="s-item-description"></div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>2,50</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi801884">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">28.	Portion Dönerfleisch</div>
                                        <div className="s-item-description"></div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>5,00</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="s-category-container js-collapsible s-category-has-picture">

                        <div className="s-category-name-container">
                            <div className="s-category-name">Pizza Steioffen</div>


                        </div>


                        <div className="s-items">

                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi804830">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">30.		Pizzabrot</div>
                                        <div className="s-item-description">mit oder ohne Knoblauch</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>3,00</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi804840">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">31.	Margherita</div>
                                        <div className="s-item-description">mit Tomatensoße und Käse</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>5,00</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi804842">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">32.	Fungi</div>
                                        <div className="s-item-description">mit Champignons</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>6,00</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi804845">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">33.	Tropicale</div>
                                        <div className="s-item-description">mit Ananas</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>6,00</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi804849">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">34.		Salami</div>
                                        <div className="s-item-description">mit Salami</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>6,00</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi804852">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">35.	Prosciutto</div>
                                        <div className="s-item-description">mit Schinken</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>6,00</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi804855">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">36.	Domenico</div>
                                        <div className="s-item-description">mit Champignons und Salami</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>6,50</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi804859">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">37.		Schinken-Salami</div>
                                        <div className="s-item-description">mit Schinken und Salami</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>6,50</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi804869">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">38.	Pizza Peperoniwurst</div>
                                        <div className="s-item-description">mit Peperoniwurst</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>6,50</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi804876">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">39.	Calzone</div>
                                        <div className="s-item-description">mit Salami, Schinken und Champignons</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>7,00</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi804878">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">40.	Frutti di Mare</div>
                                        <div className="s-item-description">mit Meeresfrüchten und Knoblauch</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>6,50</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi804882">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">41.	Stagioni</div>
                                        <div className="s-item-description">mit Salami, Schinken, Champignons und Paprika</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>7,50</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi804889">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">42.	Tonno</div>
                                        <div className="s-item-description">mit Thunfisch und Zwiebeln</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>6,50</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi804891">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">43.	Prosciutto E Fungi</div>
                                        <div className="s-item-description">mit Schinken und Champignons</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>6,50</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi804896">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">44.		Capricciosa</div>
                                        <div className="s-item-description">mit Salami, Schinken, Champignons und Artischocken</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>7,50</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi804900">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">45.	Desiderio</div>
                                        <div className="s-item-description">mit Salami, Schinken, Champignons, Paprika und Ei</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>8,00</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi804906">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">46.   Spezial</div>
                                        <div className="s-item-description">mit Salami, Zwiebeln, Knoblauch und Peperoni scharf</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>7,00</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi804941">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">47.	 Flörsheim Dalsheim</div>
                                        <div className="s-item-description">mit Salami, Schinken, Zwiebeln und Peperoni scharf</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>7,00</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi804943">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">48.	 Milano</div>
                                        <div className="s-item-description">mit Salami, Schinken und Champignons</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>7,00</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi804944">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">49.	 Marinara</div>
                                        <div className="s-item-description">mit Zwiebeln, Oliven und Thunfisch</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>7,00</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi804946">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">50. 	Teanto</div>
                                        <div className="s-item-description">mit Salami, Champignons und Artischocken</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>7,00</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi804949">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">51. 	Bella Italia</div>
                                        <div className="s-item-description">mit Schinken, Champignons und Zwiebeln</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>7,00</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi804950">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">52.   Diavolo</div>
                                        <div className="s-item-description">mit Champignons, Zwiebeln, Paprika und Peperoni</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>7,00</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi804951">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">53.	Lucana</div>
                                        <div className="s-item-description">mit Salami, Paprika, Oliven und Zwiebeln</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>7,50</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi804953">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">54.	Vegetaria</div>
                                        <div className="s-item-description">mit Broccoli, Paprika, Champignons und Tomaten</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>7,00</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi804954">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">55.	 Ufo</div>
                                        <div className="s-item-description">Gefüllt mit Salami, Paprika, Champignons und Ei</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>7,00</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi804955">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">56.	Spinat</div>
                                        <div className="s-item-description">mit Spinat und Knoblauch</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>6,00</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi804956">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">57.	Hawaii</div>
                                        <div className="s-item-description">mit Ananas und Schinken</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>7,00</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi804961">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">58.	Pizza Döner</div>
                                        <div className="s-item-description">mit Dönerfleisch, Peperoni  und  Zwiebeln</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>7,50</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi804966">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">59.	 Pizza Döner</div>
                                        <div className="s-item-description">mit Dönerfleisch, Peperoni, Zwiebeln und Weichkäse</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>8,00</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi804967">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">60.	Dogan</div>
                                        <div className="s-item-description">mit Salami, Champignons, Peperoni, Artischocken und Knoblauch</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>7,50</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi804968">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">61.	Sucuk</div>
                                        <div className="s-item-description">mit türkischer Knoblauchwurst</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>7,00</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi804969">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">62.	Teufel</div>
                                        <div className="s-item-description">mit Peperoniwurst, Zwiebeln und  Knoblauch</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>7,00</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi804970">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">63.	Broccoli Jo</div>
                                        <div className="s-item-description">mit Schinken, Champignons und Broccoli</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>6,50</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi804971">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">64.		Anna</div>
                                        <div className="s-item-description">mit Sardellen, Artischocken, Champignons und Oliven</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>7,00</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi804972">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">65.	Monsheim</div>
                                        <div className="s-item-description">mit Peperoniwurst, Schinken und Broccoli</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>7,00</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi804973">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">66.	Mozzarella</div>
                                        <div className="s-item-description">mit Mozzarella, frischen Tomaten und Rucola</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>7,00</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi804974">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">67.	Vier Formaggi</div>
                                        <div className="s-item-description">mit vier verschiedenen Käsesorten</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>7,50</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi804976">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">68.	Extravaganzza</div>
                                        <div className="s-item-description">mit Rinderhack, Schinken, Salami, Champignons, Paprika und Zwiebeln</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>8,00</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="s-category-container js-collapsible s-category-has-picture">

                        <div className="s-category-name-container">
                            <div className="s-category-name">Pide</div>


                        </div>


                        <div className="s-items" >

                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi804982">
                                <div className="s-item">
                                    <div className="s-item-info w-75 s-item-has-picture">
                                        <div className="s-item-name">75.	Pide</div>
                                        <div className="s-item-description">mit  Weichkäse</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>6,50</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi804983">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">76.	Pide</div>
                                        <div className="s-item-description">mit  Weichkäse und Ei</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>7,50</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi804984">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">77.	Pide</div>
                                        <div className="s-item-description">mit  Dönerfleisch</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>8,00</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi804986">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">78.	Pide</div>
                                        <div className="s-item-description">mit  Spinat  und Weichkäse</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>7,50</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi804987">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">79.	Pide</div>
                                        <div className="s-item-description">mit  Hackfleisch</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>8,00</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi804992">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">80.	Pide Gemischt</div>
                                        <div className="s-item-description">mit  Weichkäse, Champignons,	Oliven, Paprika und Sucuk (türkischer Knoblauchwurst)</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>9,50</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi3458889">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">82. Pide</div>
                                        <div className="s-item-description">mit Sucuk &amp; Ei</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>8,50</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="s-category-container js-collapsible s-category-has-picture">

                        <div className="s-category-name-container">
                            <div className="s-category-name">Lahmacun</div>


                        </div>


                        <div className="s-items" >

                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi804993">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">85.	Lahmacun</div>
                                        <div className="s-item-description">mit  Salat und Soße</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>4,00</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi804994">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">86.	Lahmacun</div>
                                        <div className="s-item-description">mit  Salat, Soße und Weichkäse</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>4,50</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi804995">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">87.	Lahmacun</div>
                                        <div className="s-item-description">mit Dönerfleisch, Salat und Soße</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>5,50</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi804996">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">88.	 Lahmacun</div>
                                        <div className="s-item-description">mit  Dönerfleisch, Salat, Weichkäse und Soße</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>6,00</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="s-category-container js-collapsible s-category-has-picture">

                        <div className="s-category-name-container">
                            <div className="s-category-name">Beilagen</div>


                        </div>


                        <div className="s-items" >

                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi805005">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">95.	Börek</div>
                                        <div className="s-item-description"></div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>1,00</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi805010">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">96.   Gefüllte Weinblätter</div>
                                        <div className="s-item-description">Gefüllt mit Reis in Olivenöl</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>1,00</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi805011">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">97.	  Beilagen Salat</div>
                                        <div className="s-item-description">mit Eisbergsalat, Tomaten, Gurken, Rotkraut, Weißkraut, Zwiebeln und Soße</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>3,50</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi3458900">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">98. Portion Reis / Bulgur</div>
                                        <div className="s-item-description"></div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>3,00</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="s-category-container js-collapsible s-category-has-picture">

                        <div className="s-category-name-container">
                            <div className="s-category-name">Hamburger</div>


                        </div>


                        <div className="s-items" >

                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi805039">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">100.	 Große Hamburger</div>
                                        <div className="s-item-description"></div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>4,00</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi805042">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">101.	 Mega Hamburger</div>
                                        <div className="s-item-description"></div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>5,50</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi805043">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">102.	 Große hamburger  - Menu</div>
                                        <div className="s-item-description">mit Pommes und Cola</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>6,50</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi805047">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">103.	 Mega Hamburger -  Menu</div>
                                        <div className="s-item-description">mit Pommes  und Cola</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>8,00</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="s-category-container js-collapsible s-category-has-picture">

                        <div className="s-category-name-container">
                            <div className="s-category-name">Nudeln</div>


                        </div>


                        <div className="s-items" >

                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi805059">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">104.  Bolognese</div>
                                        <div className="s-item-description">mit Hackfleisch</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>8,00</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi805060">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">105.	 Carbonara</div>
                                        <div className="s-item-description">mit Schinken, Ei, Käse und Sahne</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>8,00</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi805062">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">106.   Napoli</div>
                                        <div className="s-item-description">mit Tomatensoße</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>8,00</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi805064">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">107.	 Chef</div>
                                        <div className="s-item-description">mit Putenstreifen, Paprika, Brocoli, Sahne und Parmesankäse</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>9,00</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="s-category-container js-collapsible">

                        <div className="s-category-name-container">
                            <div className="s-category-name">Nudeln Überbacken</div>


                        </div>

                        <div className="s-category-picture"></div>

                        <div className="s-items" >

                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi805069">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">108.	 Spaghetti, Tortellini oder Rigatoni</div>
                                        <div className="s-item-description">mit Napoli Soße und Käse Überbacken</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>8,50</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi805071">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">109.   Combinazione</div>
                                        <div className="s-item-description"></div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>8,50</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi805070">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">110.   Lasagne ( Hausgemacht )</div>
                                        <div className="s-item-description"></div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>8,50</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="s-category-container js-collapsible s-category-has-picture">

                        <div className="s-category-name-container">
                            <div className="s-category-name">Schinitzel</div>


                        </div>


                        <div className="s-items" >

                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi805074">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">111.   Putenschnitzel</div>
                                        <div className="s-item-description">mit Pommes</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>7,50</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi805077">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">112.    Zigeunerschnitzel</div>
                                        <div className="s-item-description">Pommes oder Bratkartoffeln &amp; Salat</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>8,50</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi805078">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">113.    Jägerschnitzel</div>
                                        <div className="s-item-description">Pommes oder Bratkartoffeln &amp; Salat</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>8,50</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi805083">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">114.   Rahmschnitzel</div>
                                        <div className="s-item-description">Pommes oder Bratkartoffeln &amp; Salat</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>8,50</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi3458912">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">115. Pfefferschnitzel</div>
                                        <div className="s-item-description">Pommes oder Bratkartoffeln &amp; Salat</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>8,50</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi3458915">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">116. Metaxaschnitzel</div>
                                        <div className="s-item-description">Pommes oder Bratkartoffeln &amp; Salat</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>8,50</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="s-category-container js-collapsible s-category-has-picture">

                        <div className="s-category-name-container">
                            <div className="s-category-name">Salate</div>


                        </div>


                        <div className="s-items" >

                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi805084">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">120.   Bauernsalat</div>
                                        <div className="s-item-description">mit Mixsalat, Tomaten, Gurken, Weichkäse und Weißkraut</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>6,00</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi805085">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">121.	Hirtensalat</div>
                                        <div className="s-item-description">mit  Tomaten, Gurken, Zwiebeln und Weichkäse</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>7,00</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi805086">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">122.   Gemischter Salat</div>
                                        <div className="s-item-description">mit Mixsalat, Tomaten,  Gurken, Rotkraut, Zwiebeln, Weichkäse und Weißkraut</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>7,00</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi805087">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">123.   Nizza Salat</div>
                                        <div className="s-item-description">mit  Thunfisch, Mixsalat, Tomaten, Gurken, Mais, Ei, Paprika und Weichkäse</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>7,50</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi805088">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">124.   Italia Salat</div>
                                        <div className="s-item-description">mit  Schinken, Mixsalat, Tomaten,	Gurken, Mais, Ei, Paprika und Weichkäse</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>7,50</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi805089">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">125.   Dogan Salat</div>
                                        <div className="s-item-description">mit Mixsalat, Tomaten, Gurken, Rotkraut, Zwiebeln, Weichkäse und Dönerfleisch</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>8,50</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi805090">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">126.   Weichkäse Überbacken</div>
                                        <div className="s-item-description">mit oder ohne Peperoni</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>7,00</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi805094">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">127.   Türkischer Salat</div>
                                        <div className="s-item-description">mit Mixsalat, Tomaten, Gurken, Oliven, Zwiebeln, Mais und Weichkäse</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>7,00</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi805093">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">128.   PutenSalat</div>
                                        <div className="s-item-description">mit Mixsalat, Tomaten, Zwiebeln, Paprika und Putenstreifen</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>8,50</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="s-category-container js-collapsible s-category-has-picture">

                        <div className="s-category-name-container">
                            <div className="s-category-name">Suppen <span>Nur in der Filiale Flörsheim-Dalsheim erhältlich</span></div>


                        </div>


                        <div className="s-items" >

                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi3458950">
                                <div className="s-item">
                                    <div className="s-item-info w-75 s-item-has-picture">
                                        <div className="s-item-name">130.   Mercimek / Linsensuppe</div>
                                        <div className="s-item-description"></div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>4,50</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="s-category-container js-collapsible s-category-has-picture">

                        <div className="s-category-name-container">
                            <div className="s-category-name">Vorspeisen <span>Nur in der Filiale Flörsheim-Dalsheim erhältlich</span></div>


                        </div>


                        <div className="s-items" >

                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi3458968">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">143.   Cacik - Tzatziki</div>
                                        <div className="s-item-description">Joghurt mit Knoblauch &amp; Gurken</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>4,00</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi3458985">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">144.   Antep Ezme</div>
                                        <div className="s-item-description">Scharfe Gemüsepaste mit Chili, Paprika &amp; Knoblauch</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>5,00</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi3458995">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">145.   Haydari</div>
                                        <div className="s-item-description">Joghurtspezialität mit Kräutern &amp; Knoblauch</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>3,50</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi3458999">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">146.   Gegrillte Peperoni</div>
                                        <div className="s-item-description">mit Knoblauch</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>5,00</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="s-category-container js-collapsible s-category-has-picture">

                        <div className="s-category-name-container">
                            <div className="s-category-name">Grillspezialitäten <span>Nur in der Filiale Flörsheim-Dalsheim erhältlich</span></div>


                        </div>


                        <div className="s-items" >

                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi3459023">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">160.  Adana Kebap ( Lammhackfleischspieß )</div>
                                        <div className="s-item-description">gegrill. Hackfleisch mit Reis od. Bulgur od. Pommes &amp; Salat</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>11,50</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi3459027">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">161.  Kuzu Sis Kebap ( Lammspieß )</div>
                                        <div className="s-item-description">gegrill. Lammfleisch mit Reis od. Bulgur od. Pommes &amp; Salat</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>12,50</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi3459037">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">162.  Pirzola ( Lammkotellets )</div>
                                        <div className="s-item-description">gegrill. Lammkotellets mit Reis od. Bulgur od. Pommes &amp; Salat</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>14,50</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi3459040">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">163.  Gemischter Grillteller</div>
                                        <div className="s-item-description">verschidene gegrill. Fleischsorten mit Reis od. Bulgur od. Pommes &amp; Salat</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>15,50</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi3459042">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">164.  Gemischter Grillteller Für 2 Personen</div>
                                        <div className="s-item-description">verschidene gegrill. Fleischsorten mit Reis od. Bulgur od. Pommes &amp; Salat</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>30,00</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi3459044">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">165.  Tavuk Sis ( Hähchenspieß )</div>
                                        <div className="s-item-description">gegrill. Hähchenfleisch mit Reis od. Bulgur od. Pommes &amp; Salat</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>10,50</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="s-category-container js-collapsible s-category-has-picture">

                        <div className="s-category-name-container">
                            <div className="s-category-name">Steak <span>Nur in der Filiale Flörsheim-Dalsheim erhältlich</span></div>


                        </div>


                        <div className="s-items" >

                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi3459066">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">191.  RumSteak</div>
                                        <div className="s-item-description">mit kräuterbutter, Zwiebel, pfeffersoße, pommes oder geb. Kartoffel &amp; Tzatziki</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>18,50</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="s-category-container js-collapsible s-category-has-picture">

                        <div className="s-category-name-container">
                            <div className="s-category-name">süßspeisen </div>


                        </div>


                        <div className="s-items" >

                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi3459070">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">Revani</div>
                                        <div className="s-item-description">Kuchen aus Grieß mit Vanillezucker, Zuckersirup &amp; Kokos.</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>2,50</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="s-category-container js-collapsible s-category-has-picture">

                        <div className="s-category-name-container">
                            <div className="s-category-name">Alkoholfreie Getränke</div>


                        </div>


                        <div className="s-items" >

                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi805112">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">Coca Cola</div>
                                        <div className="s-item-description"></div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>2,00</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi810538">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">Cola Zero</div>
                                        <div className="s-item-description"></div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>2,00</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi810537">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">Cola Light</div>
                                        <div className="s-item-description"></div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>2,00</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi810540">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">Fanta</div>
                                        <div className="s-item-description"></div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>2,00</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi810539">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">Mezzo Mix</div>
                                        <div className="s-item-description"></div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>2,00</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi810545">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">Sprite</div>
                                        <div className="s-item-description"></div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>2,00</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi805114">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">Mineralwasser</div>
                                        <div className="s-item-description"></div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>1,50</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi805118">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">Uludag</div>
                                        <div className="s-item-description"></div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>2,00</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi810546">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">Apfelschorle</div>
                                        <div className="s-item-description"></div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>2,00</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi805121">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">Ayran</div>
                                        <div className="s-item-description">0.2L</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>1,50</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi805123">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">Alkoholfreies Bier</div>
                                        <div className="s-item-description">0.33L</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>2,50</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi805126">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">Energy Drink</div>
                                        <div className="s-item-description">0.5L</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>2,50</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi810547">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">Früchtsäfte</div>
                                        <div className="s-item-description">0.33L</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>1,50</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi805127">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">Alkoholfreies Hefe Weizen</div>
                                        <div className="s-item-description">0.5L</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>2,80</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="s-category-container js-collapsible s-category-has-picture">

                        <div className="s-category-name-container">
                            <div className="s-category-name">Alkoholische Getränke</div>


                        </div>


                        <div className="s-items" >

                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi805145">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">Verschiedene Weinsorten    -  Weis oder Rot</div>
                                        <div className="s-item-description">0,75 L</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>7,50</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi805147">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">Hefe Weizen</div>
                                        <div className="s-item-description">0.5L</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>2,50</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi805148">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">Weinshorle       ( Vor Ort zum genießen)</div>
                                        <div className="s-item-description">0.5L</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>3,00</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi805159">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">Glass Wein    ( Vor Ort zum genießen)</div>
                                        <div className="s-item-description">0,2 L</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>2,50</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi3476067">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">Bitburger</div>
                                        <div className="s-item-description">0,33L</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>2,50</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi3476069">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">Warsteiner,</div>
                                        <div className="s-item-description">0,33L</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>2,50</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi3476070">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">Mixery</div>
                                        <div className="s-item-description">0,33L</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>2,50</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi3476066">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">Radler</div>
                                        <div className="s-item-description">0,33L</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>2,50</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi3476083">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">Beck’s</div>
                                        <div className="s-item-description">0,33L</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>2,50</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi3476108">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">Efes Pilsen</div>
                                        <div className="s-item-description">0,33L</div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>2,50</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>

                        </div>
                    </div>
                    <div className="s-category-container js-collapsible s-category-has-picture">

                        <div className="s-category-name-container">
                            <div className="s-category-name">Heiße Getränke</div>


                        </div>


                        <div className="s-items" >

                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi805169">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">Kaffee und  Espresso    ( Vor Ort zum genießen)</div>
                                        <div className="s-item-description"></div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>1,30</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                            <div className="s-item-container js-s-item" data-test-btn-menu-item="" id="mi805170">
                                <div className="s-item">
                                    <div className="s-item-info w-75">
                                        <div className="s-item-picture"></div>
                                        <div className="s-item-name">Latte Macchiato und Cappuccino      ( Vor Ort zum genießen)</div>
                                        <div className="s-item-description"></div>
                                    </div>

                                    <div className="pl-0 pr-0 item-tags-container">
                                        <div className="s-item-price">
                                            <span>1,80</span>
                                        </div>
                                        <div className="s-item-tags"></div>
                                    </div>

                                </div>
                            </div>
                        </div>
                    </div>

            </section>
        </div>

    </Layout>
)

export default FoodmenuFloersheim
